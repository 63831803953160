import React from "react";
import LoadingMask from "../../../Components/LoadingMask";
import { Modal, Form, Input, Select } from "antd";

const { Option } = Select;
const { Item } = Form;

const CreateRecord = (props) => {
  const { visible, onCreate, onCancel, loadingCreate } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Ingrese datos"
      okText="Confirmar"
      cancelText="Cancelar"
      style={{ position: "relative" }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {loadingCreate && <LoadingMask />}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Item
          name="firstName"
          label="Nombre"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un nombre",
            },
          ]}
        >
          <Input placeholder="Indique su nombre" />
        </Item>
        <Item
          name="lastName"
          label="Apellido"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un apellido",
            },
          ]}
        >
          <Input placeholder="Indique su apellido" />
        </Item>
        <Item
          name="rut"
          label="Rut"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un rut",
            },
          ]}
        >
          <Input placeholder="Ingrese su rut" />
        </Item>
        <Item
          name="phone"
          label="Telefóno"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un número de contacto",
            },
          ]}
        >
          <Input placeholder="Ingrese su número de contacto." />
        </Item>
        <Item
          name="email"
          label="E-mail"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un E-mail válido",
            },
          ]}
        >
          <Input placeholder="Ingrese correo electrónico" />
        </Item>
        <Item
          name="role"
          label="Rol"
          rules={[
            {
              required: true,
              message: "Por favor ingrese rol",
            },
          ]}
        >
          <Select placeholder="Seleccione rol">
            <Option value="NURSE">Enfermera</Option>
            <Option value="SUPERVISOR">Supervisor</Option>
          </Select>
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateRecord;
