import React, { useEffect, useState } from "react";
import Handler from "./handler";
import { ALL_SPECIALTIES, PROFESSIONAL_BY_ID } from "../../../Queries";
import Loading from "../../../Components/Loading";
import gStyles from "../../GlobalScreens.module.css";

import { Empty } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router-dom";

const UpdateScreen = () => {
  const [professional, setProfessional] = useState({});
  let { params } = useRouteMatch();
  let id = params.id;
  let content;

  const { loading, error, data } = useQuery(PROFESSIONAL_BY_ID, {
    variables: { id },
  });
  const { data: specialtyData } = useQuery(ALL_SPECIALTIES);

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) setProfessional(data.ProfessionalById);
  }, [loading, data]);

  if (Object.keys(professional).length === 0) content = <Loading />;
  else if (error) content = <Empty />;
  else
    content = (
      <Handler
        id={id}
        professional={professional}
        specialtyData={specialtyData ? specialtyData.AllSpecialties : []}
      />
    );

  return <div className={gStyles.mainContent}>{content}</div>;
};

export default UpdateScreen;
