import React, { useEffect, useState } from "react";
import Handler from "./handler";
import Loading from "../../../Components/Loading";
import { DEPARTMENT_BY_ID, ALL_COST_CENTER } from "../../../Queries";
import gStyles from "../../GlobalScreens.module.css";

import { Empty } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router-dom";

const UpdateScreen = () => {
  const [department, setDepartment] = useState({});
  let { params } = useRouteMatch();
  let id = params.id;
  let content;

  const { loading, error, data } = useQuery(DEPARTMENT_BY_ID, {
    variables: { id },
  });
  const { data: ccData } = useQuery(ALL_COST_CENTER);

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) {
      setDepartment(data.DepartmentById);
    }
  }, [loading, data]);

  if (Object.keys(department).length === 0) content = <Loading />;
  else if (error) content = <Empty />;
  else
    content = (
      <Handler
        id={id}
        department={department}
        ccData={ccData ? ccData.AllCostCenters : []}
      />
    );

  return <div className={gStyles.mainContent}>{content}</div>;
};

export default UpdateScreen;
