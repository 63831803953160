import React, { useState } from 'react';
import { typeMenu } from './constant'

import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';

const { Sider, Content } = Layout;
const { Item } = Menu;

function MainContainer({ props }) {
  const [collapsed, setCollapsed] = useState(true);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  return (
    <Layout style={{ height: '92vh', widht: '10%' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <Menu theme="dark" mode="inline">
          {typeMenu.map((items) =>
            <Item key={items.name} icon={items.icon}>
              <NavLink to={`/${items.path.toLowerCase()}`}>{items.name}</NavLink>
            </Item>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ padding: 15, height: '100%', width: '100%' }}>
          {props}
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainContainer;
