import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BoxForm from "./form";
import { UPDATE_BOX } from "../../../Queries";
import LoadingMask from "../../../Components/LoadingMask";
import { showNotification } from "../../../Helpers/notificationUtil";
import gStyles from "../../GlobalScreens.module.css";

import { Button, Card, List } from "antd";
import {
  EditOutlined,
  LeftCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";

const { Meta } = Card;

const Handler = ({ id, box, costCenterData, departmentData, ftData }) => {
  const { createdAt, name, updatedAt, isOffice } = box;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const created = moment(createdAt).format("L");
  const updated = moment(updatedAt).format("L");
  const [updateBox] = useMutation(UPDATE_BOX);
  const titleCard = isOffice ? `Oficina ${name}` : `Box ${name}`;

  const handleSubmit = async (values) => {
    const {
      name,
      isOffice,
      clcBoxId,
      costCenter,
      features,
      headquarters,
      squareMeters,
    } = values;
    setLoading(true);
    try {
      const response = await updateBox({
        variables: {
          id,
          input: {
            name,
            isOffice,
            clcBoxId,
            costCenter,
            features,
            headquarters,
            squareMeters,
          },
        },
      });
      if (typeof response === "object" && response !== null) {
        showNotification(
          "success",
          "Box Actualizado",
          "Se ha actualizado el box correctamente."
        );
        setLoading(false);
        history.push("/box");
      }
    } catch (err) {
      showNotification(
        "error",
        "Error al actualizar box",
        "El registro no se ha podido actualizar."
      );
      setLoading(false);
    }
  };

  const handleClick = () => {
    history.goBack();
  };

  return (
    <>
      <Button
        block
        shape="round"
        icon={<LeftCircleFilled />}
        onClick={handleClick}
      >
        Volver
      </Button>
      <br />
      <br />
      <Card
        title={titleCard}
        className={gStyles.createCardStyle}
        style={{ position: "relative" }}
        hoverable
      >
        {loading && <LoadingMask />}
        <Meta description={id} />
        <List
          bordered={false}
          size="small"
          footer={
            <div className={gStyles.dates}>
              <div className={gStyles.insideDates}>
                <PlusCircleOutlined />
                <span>{created}</span>
              </div>
              <div className={gStyles.insideDates}>
                <span>{updated}</span>
                <EditOutlined />
              </div>
            </div>
          }
        >
          <BoxForm
            box={box}
            costCenterData={costCenterData}
            departmentData={departmentData}
            ftData={ftData}
            handleSubmit={handleSubmit}
          />
        </List>
      </Card>
      <br />
    </>
  );
};

export default Handler;
