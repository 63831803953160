import React, { useState } from "react";
import ExportToExcel from "./Components/ExportToExcel";
import styles from "./Home.module.css";

import { Row, Col, DatePicker } from "antd";
import { FileOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import "moment/locale/es";

function Home() {
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const dateFormat = "YYYY-MM-DD";

  const handleDatePicker = (date, dateString) => {
    if (date !== null) {
      setDate(`${dateString}-01`);
    }
  };
  return (
    <Row justify="center" className={styles.containerHome}>
      <Col span={24} className={styles.contentHome}>
        <FileOutlined style={{ fontSize: "90px", color: "#AEB1BB" }} />
        <h1>Exportar Excel</h1>
        <h3 style={{ marginBottom: "16px" }}>
          Escoge la fecha que deseas exportar.
        </h3>
        <p>Exporta los bloques rentados por doctor acorde a cada servicio.</p>
        <Row justify="center" style={{ width: "40%", marginTop: "24px" }}>
          <Col span={12} style={{ padding: "0px 8px" }}>
            <DatePicker
              style={{ width: "100%" }}
              onChange={handleDatePicker}
              defaultValue={moment(date.toString(), dateFormat)}
              locale={locale}
              picker="month"
            />
          </Col>
          <Col span={12} style={{ padding: "0px 8px" }}>
            <ExportToExcel date={date} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Home;
