import { gql } from "apollo-boost";

const ALL_DEPARTMENT = gql`
  query {
    AllDepartments {
      _id
      name
      clcDepartmentId
      costCenters {
        _id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const DEPARTMENT_BY_ID = gql`
  query DepartmentById($id: ID!) {
    DepartmentById(_id: $id) {
      _id
      name
      clcDepartmentId
      costCenters {
        _id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment($input: createDepartmentInput!) {
    createDepartment(input: $input) {
      name
      clcDepartmentId
      costCenters {
        _id
      }
    }
  }
`;

const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($id: ID!) {
    deleteDepartment(_id: $id)
  }
`;

const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment($id: ID!, $input: updateDepartmentInput!) {
    updateDepartment(_id: $id, input: $input) {
      _id
      name
      clcDepartmentId
      costCenters {
        _id
      }
    }
  }
`;

export {
  ALL_DEPARTMENT,
  CREATE_DEPARTMENT,
  DEPARTMENT_BY_ID,
  DELETE_DEPARTMENT,
  UPDATE_DEPARTMENT,
};
