import React, { useEffect, useState } from "react";
import {
  ALL_PROFESSIONAL,
  ALL_SPECIALTIES,
  CREATE_PROFESSIONAL,
  DELETE_PROFESSIONAL,
} from "../../Queries";
import CreateRecord from "./create";
import LoadingMask from "../../Components/LoadingMask";
import gStyle from "../GlobalScreens.module.css";
import Render from "./render";
import { showNotification } from "../../Helpers/notificationUtil";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button, Input } from "antd";
import _ from "lodash";

const ProfessionalScreen = () => {
  const [professionals, setProfessionals] = useState([]);
  const [professionalsFiltered, setProfessionalsFiltered] = useState([]);
  const [creating, setCreating] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [visible, setVisible] = useState(false);

  const { loading, data, refetch } = useQuery(ALL_PROFESSIONAL, {
    fetchPolicy: "network-only",
  });
  const { data: specialtyData } = useQuery(ALL_SPECIALTIES);
  const [createProfessional] = useMutation(CREATE_PROFESSIONAL);
  const [deleteProfessional] = useMutation(DELETE_PROFESSIONAL);

  const onCreate = async (values) => {
    const {
      rut,
      firstName,
      lastName,
      phone,
      email,
      specialties,
      code,
    } = values;
    setLoadingCreate(true);
    try {
      await createProfessional({
        variables: {
          input: {
            rut,
            firstName,
            lastName,
            phone,
            email,
            specialties,
            code,
          },
        },
      });
      showNotification(
        "success",
        "Profesional Creado",
        "Se ha creado el profesional correctamente."
      );
      setVisible(false);
      setCreating(false);
      setLoadingCreate(false);
      refetch();
    } catch {
      showNotification(
        "error",
        "Error al profesional",
        "El registro no se ha podido crear."
      );
      setLoadingCreate(false);
    }
  };

  const deleteRecord = async (id) => {
    setDeleteLoading(true);
    try {
      const response = await deleteProfessional({ variables: { id } });
      if (response.data.deleteProfessional) {
        showNotification(
          "success",
          "Registro eliminado",
          "Se ha eliminado el registro correctamente."
        );
        setDeleteLoading(false);
        refetch();
      } else {
        showNotification(
          "error",
          "Error al eliminar",
          "El registro no se puede eliminar, ya que, cuenta con reservas a su nombre."
        );
        setDeleteLoading(false);
      }
    } catch (err) {
      console.log("ERROR AL EJECUTAR BLOQUE DE deleteRecord ", err);
      setDeleteLoading(false);
    }
  };

  const handleSearch = (input) => {
    const filteredProfessionals = professionals.filter((item) => {
      const searchableParams = [
        "_id",
        "email",
        "name",
        "rut",
        "phone",
        "specialties[0].name",
      ];
      for (const param of searchableParams) {
        if (param === "name") {
          const name = `${item.firstName} ${item.lastName}`;
          if (name.toLowerCase().includes(input.toLowerCase())) return true;
        } else {
          const field = _.get(item, `[${param}]`, "").toLowerCase();
          if (field.includes(input.toLowerCase())) return true;
        }
      }
      return false;
    });
    setProfessionalsFiltered(filteredProfessionals);
  };

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) {
      setProfessionals(data.AllProfessionals);
      setProfessionalsFiltered(data.AllProfessionals);
    }
  }, [loading, data]);

  return (
    <div className={gStyle.mainContent}>
      <div className={gStyle.createContainer}>
        <Button
          loading={creating}
          shape="round"
          className={gStyle.createButton}
          type="primary"
          onClick={() => {
            setCreating(true);
            setVisible(true);
          }}
        >
          Crear Registro
        </Button>
        <Input.Search
          placeholder="Buscar en Profesionales"
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
          onSearch={(e) => handleSearch(e)}
          style={{ width: "85%", margin: "0px" }}
        />
      </div>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        {deleteLoading && <LoadingMask />}
        <Render
          professionals={professionalsFiltered}
          deleteRecord={deleteRecord}
          loading={loading}
        />
      </div>
      <CreateRecord
        visible={visible}
        loadingCreate={loadingCreate}
        onCreate={onCreate}
        onCancel={() => {
          setCreating(false);
          setVisible(false);
        }}
        specialtyData={specialtyData ? specialtyData.AllSpecialties : []}
      />
    </div>
  );
};

export default ProfessionalScreen;
