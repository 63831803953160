import { gql } from "apollo-boost";

const ALL_COST_CENTER = gql`
  query {
    AllCostCenters {
      _id
      name
      clcCostCenterId
      createdAt
      updatedAt
    }
  }
`;

const COST_CENTER_BY_ID = gql`
  query CostCenterById($id: ID!) {
    CostCenterById(_id: $id) {
      _id
      name
      dualMorning
      clcCostCenterId
      createdAt
      updatedAt
    }
  }
`;

const CREATE_COST_CENTER = gql`
  mutation CreateCostCenter($input: createCostCenterInput!) {
    createCostCenter(input: $input) {
      name
    }
  }
`;

const DELETE_COST_CENTER = gql`
  mutation DeleteCostCenter($id: ID!) {
    deleteCostCenter(_id: $id)
  }
`;

const UPDATE_COST_CENTER = gql`
  mutation UpdateCostCenter($id: ID!, $input: updateCostCenterInput!) {
    updateCostCenter(_id: $id, input: $input) {
      _id
      name
      clcCostCenterId
      createdAt
      updatedAt
    }
  }
`;

export {
  ALL_COST_CENTER,
  COST_CENTER_BY_ID,
  CREATE_COST_CENTER,
  DELETE_COST_CENTER,
  UPDATE_COST_CENTER,
};
