import React, { useState, useEffect } from "react";
import LoadingMask from "../../../Components/LoadingMask";
import { DEPARTMENT_BY_ID } from "../../../Queries";

import { Modal, Empty, Form, Input, Select, InputNumber, Switch } from "antd";
import { useLazyQuery } from "@apollo/react-hooks";

const { Item } = Form;
const { Option } = Select;

const CreateRecord = (props) => {
  const {
    departmentData,
    ftData,
    onCancel,
    onCreate,
    visible,
    loadingCreate,
  } = props;
  const [form] = Form.useForm();
  const [isOffice, setIsOffice] = useState(false);
  const [loadDepartment, { data }] = useLazyQuery(DEPARTMENT_BY_ID);
  const labelItemIsOffice = isOffice ? "Es una oficina" : "Es un box";
  const placeHolderName = isOffice ? "Indique oficina" : "Indique box";
  useEffect(() => {
    if (!loadingCreate) {
      setIsOffice(false);
    }
  }, [loadingCreate]);

  return (
    <Modal
      visible={visible}
      title="Ingrese datos"
      okText="Confirmar"
      cancelText="Cancelar"
      style={{ position: "relative" }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsOffice(false);
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {loadingCreate && <LoadingMask />}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Item
          name="name"
          label="Nombre"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un nombre del box",
            },
          ]}
        >
          <Input placeholder={placeHolderName} />
        </Item>
        <Item
          name="department"
          label="Departamento"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un departamento/servicio",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Seleccione departamento/servicio"
            onChange={(id) => {
              loadDepartment({ variables: { id } });
            }}
          >
            {departmentData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="costCenter" label="Centro de Costo">
          <Select
            style={{ width: "100%" }}
            placeholder="Seleccione centro de costo"
          >
            {data ? (
              data.DepartmentById.costCenters.map((item, index) => (
                <Option key={index} value={item._id}>
                  {item.name}
                </Option>
              ))
            ) : (
              <Option>
                <Empty />
              </Option>
            )}
          </Select>
        </Item>
        <Item
          name="headquarters"
          label="Sede"
          rules={[
            {
              required: true,
              message: "Por favor ingrese una sede",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Indique sede"
          >
            <Option value="CHICUREO">Sede Chicureo</Option>
            <Option value="ESTORIL">Sede Estoril</Option>
            <Option value="PEÑALOLÉN">Sede Peñalolén</Option>
          </Select>
        </Item>
        <Item name="features" label="Características">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Seleccione características"
          >
            {ftData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="clcBoxId" label="Id Clínica">
          <Input placeholder="Id de clínica" />
        </Item>
        <div style={{ display: "flex" }}>
          <Item
            name="squareMeters"
            label="Metros Cuadrados"
            style={{ width: "40%" }}
          >
            <InputNumber min={1} max={200} placeholder={1} />
          </Item>
          <Item
            name="isOffice"
            label={labelItemIsOffice}
            valuePropName="checked"
            initialValue={isOffice}
          >
            <Switch
              onChange={() => setIsOffice(!isOffice)}
              style={{ width: "60px" }}
            />
          </Item>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateRecord;
