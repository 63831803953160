import { gql } from "apollo-boost";

const ALL_BOX = gql`
  query {
    AllBoxes {
      _id
      name
      squareMeters
      features {
        _id
        name
      }
      department {
        _id
        name
      }
      costCenter {
        _id
        name
      }
      headquarters
      building
      clcBoxId
      isOffice
      createdAt
      updatedAt
    }
  }
`;

const BOX_BY_ID = gql`
  query BoxById($id: ID!) {
    BoxById(_id: $id) {
      _id
      name
      building
      clcBoxId
      isOffice
      costCenter {
        _id
        name
      }
      features {
        _id
        name
      }
      department {
        _id
      }
      headquarters
      squareMeters
      createdAt
      updatedAt
    }
  }
`;

const CREATE_BOX = gql`
  mutation CreateBox($input: createBoxInput!) {
    createBox(input: $input) {
      building
      clcBoxId
      isOffice
      costCenter {
        _id
      }
      department {
        _id
      }
      features {
        _id
      }
      headquarters
      name
      squareMeters
    }
  }
`;

const DELETE_BOX = gql`
  mutation DeleteBox($id: ID!) {
    deleteBox(_id: $id)
  }
`;

const UPDATE_BOX = gql`
  mutation UpdateBox($id: ID!, $input: updateBoxInput!) {
    updateBox(_id: $id, input: $input) {
      building
      clcBoxId
      isOffice
      costCenter {
        _id
      }
      department {
        _id
      }
      features {
        _id
      }
      headquarters
      name
      squareMeters
    }
  }
`;

export { ALL_BOX, BOX_BY_ID, CREATE_BOX, DELETE_BOX, UPDATE_BOX };
