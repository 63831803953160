import React from "react";
import LoadingMask from "../../../Components/LoadingMask";
import { Modal, Form, Input, Select } from "antd";

const { Item } = Form;
const { Option } = Select;

const CreateRecord = (props) => {
  const { visible, onCreate, onCancel, ccData, loadingCreate } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Ingrese datos"
      okText="Confirmar"
      cancelText="Cancelar"
      style={{ position: "relative" }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {loadingCreate && <LoadingMask />}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Item
          name="name"
          label="Departamento"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un departamento",
            },
          ]}
        >
          <Input placeholder="Ingrese departamento" />
        </Item>
        <Item name="costCenters" label="Centro de costos">
          <Select
            mode="tags"
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Seleccione centros de costo"
          >
            {ccData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="clcDepartmentId" label="ID de clinica">
          <Input placeholder="Id de clínica" />
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateRecord;
