import Auth from '@aws-amplify/auth';

const getAuthToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  // console.log(user)
  console.log(user.signInUserSession.idToken.jwtToken);

  return {
    Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
  };
};

export default getAuthToken;
