import React, { useState, useEffect } from "react";
import { ALL_ADMIN, CREATE_ADMIN, DELETE_ADMIN } from "../../Queries";
import Render from "./render";
import CreateRecord from "./create";
import LoadingMask from "../../Components/LoadingMask";
import { showNotification } from "../../Helpers/notificationUtil";
import user, { getAllUsers, deleteUser } from "../../Helpers/cognito";
import gStyle from "../GlobalScreens.module.css";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button, Input } from "antd";
import _ from "lodash";

const AdminScreen = () => {
  const [admins, setAdmins] = useState([]);
  const [adminsFiltered, setAdminsFiltered] = useState([]);
  const [creating, setCreating] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [visible, setVisible] = useState(false);

  const { loading, data, refetch } = useQuery(ALL_ADMIN, {
    fetchPolicy: "network-only",
  });
  const [createAdmin] = useMutation(CREATE_ADMIN);
  const [deleteAdmin] = useMutation(DELETE_ADMIN);

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) onLoad(data);
  }, [loading, data]);

  const onLoad = async (data) => {
    const { AllAdmins } = data;
    const response = await getAllUsers();
    const { Users } = response;
    const users = Users.reduce((array, userCognito) => {
      const { Username, Attributes } = userCognito;
      let user = AllAdmins.find(({ sub }) => sub !== null && sub === Username);
      if (!user) return array;
      user.email = Attributes.find(({ Name = "" }) => Name === "email").Value;
      array.push({ [Username]: user });
      return array;
    }, []);

    setAdmins(users);
    setAdminsFiltered(users);
  };

  const onCreate = async (values) => {
    let sub;
    setLoadingCreate(true);
    const { firstName, lastName, rut, role } = values;
    try {
      const response = await user(values);
      sub = response.Username;
      await createAdmin({
        variables: {
          input: {
            firstName,
            lastName,
            rut,
            sub,
            role,
          },
        },
      });
      showNotification(
        "success",
        "Usuario creado",
        "Se ha registrado el usuario correctamente."
      );
      setVisible(false);
      setCreating(false);
      setLoadingCreate(false);
      refetch();
    } catch (err) {
      showNotification(
        "error",
        "Error al crear usuario",
        "Ha habido un error en la creación de usuario."
      );
      setLoadingCreate(false);
      throw err;
    }
  };

  const deleteRecord = async (id, sub) => {
    if (sub) {
      setDeleteLoading(true);
      try {
        await deleteUser(sub);
        const response = await deleteAdmin({ variables: { id } });
        if (response.data.deleteAdmin) {
          showNotification(
            "success",
            "Registro eliminado",
            "Se ha eliminado el registro correctamente."
          );
          setDeleteLoading(false);
          refetch();
        } else {
          showNotification(
            "error",
            "Error al eliminar",
            "El registro no se puede eliminar, ya que, cuenta con reservas a su nombre."
          );
          setDeleteLoading(false);
        }
      } catch (err) {
        console.log("ERROR AL EJECUTAR BLOQUE DE deleteRecord ", err);
        setDeleteLoading(false);
      }
    }
  };

  const handleSearch = (input) => {
    const filteredAdmins = admins.filter((item) => {
      const searchableParams = ["_id", "email", "name", "sub", "rut"];
      for (const param of searchableParams) {
        const itemParam = item[Object.keys(item)[0]];
        if (param === "name") {
          const name = `${item[Object.keys(item)[0]].firstName} ${
            item[Object.keys(item)[0]].lastName
          }`;
          if (name.toLowerCase().includes(input.toLowerCase())) return true;
        } else {
          const field = _.get(itemParam, `[${param}]`, "").toLowerCase();
          if (field.includes(input.toLowerCase())) return true;
        }
      }
      return false;
    });
    setAdminsFiltered(filteredAdmins);
  };

  return (
    <>
      <div className={gStyle.mainContent}>
        <div className={gStyle.createContainer}>
          <Button
            loading={creating}
            onClick={() => {
              setCreating(true);
              setVisible(true);
            }}
            className={gStyle.createButton}
            type="primary"
            shape="round"
          >
            Crear Registro
          </Button>
          <Input.Search
            placeholder="Buscar en Administradores"
            allowClear
            onChange={(e) => handleSearch(e.target.value)}
            onSearch={(e) => handleSearch(e)}
            style={{ width: "85%", margin: "0px" }}
          />
        </div>
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          {deleteLoading && <LoadingMask />}
          <Render
            admins={adminsFiltered}
            deleteRecord={deleteRecord}
            loading={loading}
          />
        </div>
      </div>
      <CreateRecord
        visible={visible}
        loadingCreate={loadingCreate}
        onCreate={onCreate}
        onCancel={() => {
          setCreating(false);
          setVisible(false);
        }}
      />
    </>
  );
};

export default AdminScreen;
