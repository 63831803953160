export default async function drawExcel({ ReserveToExcel }) {
  if (!ReserveToExcel) return

  const blob = new Blob(ReserveToExcel, { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('hidden', '')
  a.setAttribute('href', url)
  a.setAttribute('download', 'descarga.csv')
  document.body.appendChild(a)
  a.click()
}
