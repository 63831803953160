import React from "react";
import Handler from "./handler";
import Loading from "../../../Components/Loading";
import styles from "../PeriodScreen.module.css";

import { List, Empty } from "antd";

const { Item } = List;

const Render = ({ periods, loading }) => {
  if (loading) return <Loading />;

  return periods.length > 0 ? (
    <div className={styles.listContainer}>
      <List
        pagination={{ pageSize: 8 }}
        grid={{ gutter: 16, column: 4 }}
        dataSource={periods}
        renderItem={(item) => (
          <Item>
            <Handler key={item._id} item={item} />
          </Item>
        )}
      />
    </div>
  ) : (
    <Empty />
  );
};

export default Render;
