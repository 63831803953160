import React, { useEffect, useState } from "react";
import Handler from "./handler";
import gStyles from "../../GlobalScreens.module.css";
import { ADMIN_BY_ID } from "../../../Queries";
import { getUser } from "../../../Helpers/cognito";
import Loading from "../../../Components/Loading";

import { Empty } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router-dom";

const UpdateScreen = () => {
  const [email, setEmail] = useState("");
  let { params } = useRouteMatch();
  let id = params.id;
  let content;

  const { loading, error, data } = useQuery(ADMIN_BY_ID, {
    variables: { id },
  });
  const callUser = async (sub) => {
    try {
      const response = await getUser(sub);
      let address = response.UserAttributes.find(
        ({ Name = "" }) => Name === "email"
      ).Value;
      setEmail(address);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (loading) return;
    callUser(data.AdminById.sub);
  });

  if (loading) content = <Loading />;
  else if (error) content = <Empty />;
  else {
    content = <Handler AdminById={data.AdminById} email={email} />;
  }

  return <div className={gStyles.mainContent}>{content}</div>;
};

export default UpdateScreen;
