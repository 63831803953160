import React, { useState } from "react";
import { UPDATE_ADMIN } from "../../../../Queries";
import { showNotification } from "../../../../Helpers/notificationUtil";
import LoadingMask from "../../../../Components/LoadingMask";
import { roleColor, translateRole } from "../../../../Helpers/roleUtils";
import styles from "../../AdminScreen.module.css";

import { Button, Card, Divider, Form, Select, Tag, Row, Col } from "antd";
import { useMutation } from "@apollo/react-hooks";

const { Item } = Form;
const { Option } = Select;

const Roles = (props) => {
  const { id, role } = props;
  const [userRole, setUserRole] = useState(role);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [updateAdmin] = useMutation(UPDATE_ADMIN);

  const handleSubmit = async (values) => {
    const { role } = values;
    setLoading(true);
    try {
      await updateAdmin({
        variables: {
          id,
          input: {
            role,
          },
        },
      });
      showNotification(
        "success",
        "Rol asignado",
        "Se ha asignado el rol correctamente."
      );
      setUserRole(role);
      setLoading(false);
    } catch (err) {
      showNotification(
        "error",
        "Error al asignar rol",
        "Ha habido un error en la asignación de rol."
      );
      console.log(err);
      setLoading(false);
      throw err;
    }
  };

  const roleOptions = [{ NURSE: "Enfermera" }, { SUPERVISOR: "Supervisor" }];

  return (
    <>
      <Card
        title={
          <Row>
            <Col span={18}>
              <h1>Cambiar rol de usuario</h1>
            </Col>
            <Col span={6} className={styles.updateCardHeadTag}>
              <Tag color={roleColor(userRole)}>{translateRole(userRole)}</Tag>
            </Col>
          </Row>
        }
        style={{ position: "relative" }}
        className={styles.insideCardForm}
        hoverable
      >
        {loading && <LoadingMask />}
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            role: userRole,
          }}
          onFinish={() =>
            form
              .validateFields()
              .then((values) => {
                handleSubmit(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              })
          }
        >
          <Item
            name="role"
            label="Roles"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Seleccione rol">
              {roleOptions.map((items, index) =>
                Object.keys(items).map((key) => (
                  <Option key={index} value={key}>
                    {items[key]}
                  </Option>
                ))
              )}
            </Select>
          </Item>
          <Divider />
          <Button htmlType="submit" block shape="round">
            Cambiar
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default Roles;
