import moment from 'moment'
import _ from 'lodash'

export default function computePeriodData(period) {
  const list = []
  if (period)
    period.configuration.forEach(item => {
      list.push({
        name: item.name,
        times: timesToString(item),
      })
    })

  return _.chunk(list, 1)
}

const timesToString = configuration => {
  return `${moment(formatTime(configuration.from), 'HHmm').format('HH:mm')} a ${moment(
    formatTime(configuration.to), 'HHmm'
  ).format('HH:mm')}`
}

const formatTime = timeNum => {
  const format = `0${timeNum}`
  return format.substr(format.length - 4)
}
