import React from 'react';
import { Route } from 'react-router-dom';
import AdminScreen from './AdminScreen';
import updateScreen from './updateScreen';

const indexAdmin = () => {
  return (
    <>
      <Route exact path="/admin" component={AdminScreen} />
      <Route exact path="/admin/:id" component={updateScreen} />
    </>
  );
};

export default indexAdmin;
