import React from 'react'
import {
    BookFilled,
    BookOutlined,
    CarryOutFilled,
    CreditCardFilled,
    FieldTimeOutlined,
    FolderOpenFilled,
    SkinFilled,
    SmileFilled,
} from '@ant-design/icons';

const typeMenu = [
    {
        name: 'Administradores',
        path: 'Admin',
        icon: <SmileFilled />,
    },

    {
        name: 'Boxes',
        path: 'Box',
        icon: <CarryOutFilled />
    },
    {
        name: 'Centros de Costo',
        path: 'CostCenter',
        icon: <BookFilled />
    },
    {
        name: 'Servicios',
        path: 'Department',
        icon: <CreditCardFilled />
    },
    {
        name: 'Caracteristicas de Box',
        path: 'Feature',
        icon: <FolderOpenFilled />
    },
    {
        name: 'Periodos',
        path: 'Period',
        icon: <FieldTimeOutlined />
    },
    {
        name: 'Profesionales',
        path: 'Professional',
        icon: <SkinFilled />
    },
    {
        name: 'Especialidades',
        path: 'Specialty',
        icon: <BookOutlined />
    },
];

export { typeMenu }