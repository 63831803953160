import ApolloClient from 'apollo-boost';
import getAuthToken from '../Helpers/getAuthToken';

const Client = new ApolloClient({
  uri: process.env.REACT_APP_URL_ENDPOINT,
  request: async operation => {
    const Authorization = await getAuthToken();
    operation.setContext({
      headers: Authorization,
    });
  },
});

export { Client };
