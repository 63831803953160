import React from "react";

import { Row, Col, Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";

const Loading = () => {
  const antIcon = <SyncOutlined style={{ fontSize: 45 }} spin />;
  return (
    <Row justify={"center"}>
      <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
        <Spin indicator={antIcon} tip="Cargando" />
      </Col>
    </Row>
  );
};

export default Loading;
