import React from 'react';
import { Route } from 'react-router-dom';
import updateScreen from './updateScreen';
import ProfessionalScreen from './ProfessionalScreen';

const indexProfessional = () => {
  return (
    <>
      <Route exact path="/professional" component={ProfessionalScreen} />
      <Route exact path="/professional/:id" component={updateScreen} />
    </>
  );
};

export default indexProfessional;
