import { gql } from 'apollo-boost';

const ALL_FEATURE = gql`
  query {
    AllFeatures {
      _id
      name
      createdAt
      updatedAt
    }
  }
`;

const CREATE_FEATURE = gql`
  mutation CreateFeature($input: createFeatureInput!) {
    createFeature(input: $input) {
      name
    }
  }
`;

const FEATURE_BY_ID = gql`
  query FeatureById($id: ID!) {
    FeatureById(_id: $id) {
      _id
      name
      createdAt
      updatedAt
    }
  }
`;

const DELETE_FEATURE = gql`
  mutation DeleteFeature($id: ID!) {
    deleteFeature(_id: $id)
  }
`;

const UPDATE_FEATURE = gql`
  mutation UpdateFeature($id: ID!, $input: updateFeatureInput!) {
    updateFeature(_id: $id, input: $input) {
      _id
      name
    }
  }
`;

export {
  ALL_FEATURE,
  CREATE_FEATURE,
  DELETE_FEATURE,
  FEATURE_BY_ID,
  UPDATE_FEATURE,
};
