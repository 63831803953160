import { gql } from 'apollo-boost';

const ADMIN_BY_ID = gql`
  query adminById($id: ID!) {
    AdminById(_id: $id) {
      _id
      firstName
      lastName
      role
      rut
      sub
      createdAt
      updatedAt
    }
  }
`;

const ALL_ADMIN = gql`
  query {
    AllAdmins {
      _id
      firstName
      lastName
      role
      rut
      sub
      createdAt
      updatedAt
    }
  }
`;

const CREATE_ADMIN = gql`
  mutation CreateAdmin($input: createAdminInput!) {
    createAdmin(input: $input) {
      firstName
      lastName
      role
      rut
      sub
    }
  }
`;

const UPDATE_ADMIN = gql`
  mutation UpdateAdmin($id: ID!, $input: updateAdminInput!) {
    updateAdmin(_id: $id, input: $input) {
      firstName
      lastName
      role
      rut
    }
  }
`;

const DELETE_ADMIN = gql`
  mutation DeleteAdmin($id: ID!) {
    deleteAdmin(_id: $id)
  }
`;

export { ADMIN_BY_ID, ALL_ADMIN, CREATE_ADMIN, DELETE_ADMIN, UPDATE_ADMIN };
