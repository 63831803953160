import React from "react";
import AppLayout from "./AppLayout";
import config from "../Configs/AmplifyConfig";
import Home from "./Home";
import indexAdmin from "./AdminScreen";
import indexBox from "./BoxScreen";
import indexCostCenter from "./CostCenterScreen";
import indexDepartment from "./DepartmentScreen";
import indexFeature from "./FeatureScreen";
import indexPeriod from "./PeriodScreen";
import indexProfessional from "./ProfessionalScreen";
import indexSpecialty from "./SpecialtyScreen";

import Amplify from "aws-amplify";
import { LoginScreen } from "@rokket-labs/clc-parts";
import moment from "moment-timezone";
import "moment/locale/es";
import { Route, Switch } from "react-router-dom";

Amplify.configure(config);
moment.locale("es");
moment.tz.setDefault("America/Santiago");

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  );
};

const Screens = () => {
  return (
    <Switch>
      <Route
        path="/login"
        component={(props) => {
          return <LoginScreen label="Mantenedores" path="/" {...props} />;
        }}
      />
      <AppLayoutRoute exact path="/" component={Home} />
      <AppLayoutRoute path="/admin" component={indexAdmin} />
      <AppLayoutRoute path="/box" component={indexBox} />
      <AppLayoutRoute path="/costCenter" component={indexCostCenter} />
      <AppLayoutRoute path="/department" component={indexDepartment} />
      <AppLayoutRoute path="/feature" component={indexFeature} />
      <AppLayoutRoute path="/period" component={indexPeriod} />
      <AppLayoutRoute path="/professional" component={indexProfessional} />
      <AppLayoutRoute path="/specialty" component={indexSpecialty} />
      <AppLayoutRoute component={() => <h1>404</h1>} />
    </Switch>
  );
};

export default Screens;
