import React from "react";
import gStyles from "../../GlobalScreens.module.css";
import styles from "../ProfessionalScreen.module.css";
import rutFormat from "../../../Helpers/rutUtil";

import { Card, Col, Button, List, Modal, Tag, Row } from "antd";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";

const { Meta } = Card;
const { Item } = List;
const { confirm } = Modal;

const Handler = (props) => {
  const { item, deleteRecord } = props;
  const {
    _id,
    firstName,
    lastName,
    rut,
    phone,
    email,
    specialties,
    createdAt,
    updatedAt,
  } = item;
  const created = moment(createdAt).format("L");
  const updated = moment(updatedAt).format("L");
  const history = useHistory();
  let { url } = useRouteMatch();

  const showDeleteConfirm = () => {
    confirm({
      title: "¿Estas seguro que deseas eliminar este registro?",
      icon: <ExclamationCircleOutlined />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteRecord(item._id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleClick = (id) => {
    history.push(`${url}/${id}`);
  };

  return (
    <>
      <Card
        bodyStyle={{ padding: 0 }}
        className={gStyles.cardStyle}
        style={{ height: "400px" }}
        hoverable
        title={`${firstName} ${lastName}`}
      >
        <div onClick={() => handleClick(_id)}>
          <Meta description={_id} style={{ padding: 10 }} />
          <List
            bordered={false}
            size="small"
            footer={
              <div className={gStyles.dates}>
                <div className={gStyles.insideDates}>
                  <PlusCircleOutlined />
                  <span>{created}</span>
                </div>
                <div className={gStyles.insideDates}>
                  <span>{updated}</span>
                  <EditOutlined />
                </div>
              </div>
            }
          >
            <div style={{ height: "180px", overflowY: "auto" }}>
              {rut ? <Item>{rutFormat(rut)}</Item> : null}
              {phone ? <Item>Fono: {phone}</Item> : <Item>Fono: ~~</Item>}
              {email ? (
                <Item>
                  Correo:{" "}
                  <Tag color="blue" className={styles.specialty}>
                    {email}
                  </Tag>
                </Item>
              ) : (
                <Item>Corre: ~~</Item>
              )}
              {specialties.length > 0 ? (
                <Item className={styles.itemSpecialties}>
                  <p
                    style={{
                      textAlign: "left",
                      width: "100%",
                      marginBottom: "2px",
                    }}
                  >
                    Especialidades:
                  </p>{" "}
                  {specialties.map((depa) => (
                    <Tag
                      style={{ width: "100%", margin: "1px 0px" }}
                      key={depa._id}
                      color="#3b5999"
                    >
                      {depa.name}
                    </Tag>
                  ))}
                </Item>
              ) : (
                <Item>Especialidades: ~~</Item>
              )}
            </div>
          </List>
        </div>
        <Row justify="end" align="bottom">
          <Col sm={6} style={{ margin: 10 }}>
            <Button
              type="primary"
              danger
              onClick={showDeleteConfirm}
              className={gStyles.deleteButton}
            >
              Borrar
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Handler;
