import { gql } from 'apollo-boost';

const ALL_SPECIALTIES = gql`
  query {
    AllSpecialties {
      _id
      name
      clcSpecialtyId
      createdAt
      updatedAt
    }
  }
`;

const CREATE_SPECIALTY = gql`
  mutation CreateSpecialty($input: createSpecialtyInput!) {
    createSpecialty(input: $input) {
      name
      clcSpecialtyId
    }
  }
`;

const DELETE_SPECIALTY = gql`
  mutation DeleteSpecialty($id: ID!) {
    deleteSpecialty(_id: $id)
  }
`;

const SPECIALTY_BY_ID = gql`
  query SpecialtyById($id: ID!) {
    SpecialtyById(_id: $id) {
      _id
      name
      clcSpecialtyId
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_SPECIALTY = gql`
  mutation UpdateSpecialty($id: ID!, $input: updateSpecialtyInput!) {
    updateSpecialty(_id: $id, input: $input) {
      name
      clcSpecialtyId
    }
  }
`;

export {
  ALL_SPECIALTIES,
  CREATE_SPECIALTY,
  DELETE_SPECIALTY,
  SPECIALTY_BY_ID,
  UPDATE_SPECIALTY,
};
