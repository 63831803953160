import React, { useEffect, useState } from "react";
import {
  ALL_BOX,
  ALL_DEPARTMENT,
  ALL_FEATURE,
  CREATE_BOX,
  DELETE_BOX,
} from "../../Queries";
import CreateRecord from "./create";
import LoadingMask from "../../Components/LoadingMask";
import gStyle from "../GlobalScreens.module.css";
import Render from "./render";
import { showNotification } from "../../Helpers/notificationUtil";

import { Button, Input } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";

const BoxScreen = () => {
  const [boxes, setBoxes] = useState([]);
  const [boxesFiltered, setBoxesFiltered] = useState([]);
  const [creating, setCreating] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [visible, setVisible] = useState(false);

  const { loading, data, refetch } = useQuery(ALL_BOX, {
    fetchPolicy: "network-only",
  });
  const { data: departmentData } = useQuery(ALL_DEPARTMENT);
  const { data: ftData } = useQuery(ALL_FEATURE);
  const [createBox] = useMutation(CREATE_BOX);
  const [deleteBox] = useMutation(DELETE_BOX);

  const onCreate = async (values) => {
    setLoadingCreate(true);
    const {
      isOffice,
      clcBoxId,
      costCenter,
      department,
      features,
      headquarters,
      name,
      squareMeters,
    } = values;
    try {
      await createBox({
        variables: {
          input: {
            name: name.toString(),
            clcBoxId,
            costCenter,
            department,
            features,
            headquarters,
            squareMeters,
            isOffice,
          },
        },
      });
      showNotification(
        "success",
        "Box Creado",
        "Se ha creado el box correctamente."
      );
      setVisible(false);
      setCreating(false);
      setLoadingCreate(false);
      refetch();
    } catch {
      showNotification(
        "error",
        "Error al crear box",
        "El registro no se ha podido crear."
      );
      setLoadingCreate(false);
    }
  };

  const deleteRecord = async (id) => {
    setDeleteLoading(true);
    try {
      const response = await deleteBox({ variables: { id } });
      if (response.data.deleteBox) {
        showNotification(
          "success",
          "Registro eliminado",
          "Se ha eliminado el registro correctamente."
        );
        setDeleteLoading(false);
        refetch();
      } else {
        showNotification(
          "error",
          "Error al eliminar",
          "El registro no se puede eliminar, ya que, cuenta con reservas a su nombre."
        );
        setDeleteLoading(false);
      }
    } catch (err) {
      console.log("ERROR AL EJECUTAR BLOQUE DE deleteRecord ", err);
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) {
      const newBoxData = data.AllBoxes.map((item) => {
        const newName = item.isOffice
          ? `Oficina ${item.name}`
          : `Box ${item.name}`;
        return {
          ...item,
          name: newName,
        };
      });
      setBoxes(newBoxData);
      setBoxesFiltered(newBoxData);
    }
  }, [loading, data]);

  const handleSearch = (input) => {
    const filteredBoxes = boxes.filter((item) => {
      const searchableParams = [
        "name",
        "_id",
        "headquarters",
        "costCenter.name",
        "department.name",
      ];
      for (const param of searchableParams) {
        const field = _.get(item, `[${param}]`, "").toLowerCase();
        if (field.includes(input.toLowerCase())) return true;
      }
      return false;
    });
    setBoxesFiltered(filteredBoxes);
  };

  return (
    <div className={gStyle.mainContainer}>
      <div className={gStyle.createContainer}>
        <Button
          loading={creating}
          type="primary"
          shape="round"
          className={gStyle.createButton}
          onClick={() => {
            setCreating(true);
            setVisible(true);
          }}
        >
          Crear Registro
        </Button>
        <Input.Search
          placeholder="Buscar en Boxes"
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
          onSearch={(e) => handleSearch(e)}
          style={{ width: "85%", margin: "0px" }}
        />
      </div>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        {deleteLoading && <LoadingMask />}
        <Render
          boxes={boxesFiltered}
          deleteRecord={deleteRecord}
          loading={loading}
        />
      </div>
      <CreateRecord
        visible={visible}
        loadingCreate={loadingCreate}
        onCreate={onCreate}
        onCancel={() => {
          setCreating(false);
          setVisible(false);
        }}
        departmentData={departmentData ? departmentData.AllDepartments : []}
        ftData={ftData ? ftData.AllFeatures : []}
      />
    </div>
  );
};

export default BoxScreen;
