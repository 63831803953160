import React from "react";
import LoadingMask from "../../../Components/LoadingMask";
import { Modal, Form, Input, Select, InputNumber } from "antd";

const { Item } = Form;
const { Option } = Select;

const CreateRecord = (props) => {
  const { visible, onCreate, onCancel, specialtyData, loadingCreate } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Ingrese datos"
      okText="Confirmar"
      cancelText="Cancelar"
      style={{ position: "relative" }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {loadingCreate && <LoadingMask />}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Item
          name="firstName"
          label="Nombre"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su nombre",
            },
          ]}
        >
          <Input placeholder="Indique nombre del doctor" />
        </Item>
        <Item
          name="lastName"
          label="Apellido"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su apellido",
            },
          ]}
        >
          <Input placeholder="Indique apellido del doctor" />
        </Item>
        <Item
          name="rut"
          label="Rut"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su rut",
            },
          ]}
        >
          <Input placeholder="Ingrese rut" />
        </Item>
        <Item
          name="phone"
          label="Telefono"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su telefono",
            },
          ]}
        >
          <Input placeholder="Ingrese telefono" />
        </Item>
        <Item
          name="email"
          label="E-mail"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su correo electrónico",
            },
          ]}
        >
          <Input placeholder="Ingrese correo electrónico" />
        </Item>
        <Item name="code" label="Código">
          <InputNumber min={1} placeholder={1} />
        </Item>
        <Item name="specialties" label="Especialidad">
          <Select
            mode="tags"
            placeholder="Seleccione departamento"
            style={{ width: "100%" }}
            tokenSeparators={[","]}
          >
            {specialtyData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateRecord;
