import React from "react";

import { Button, Form, Input, Select } from "antd";
import { CreditCardFilled, EditOutlined } from "@ant-design/icons";

const { Item } = Form;
const { Option } = Select;

const DepartmentForm = ({ department, ccData, handleSubmit }) => {
  const [form] = Form.useForm();
  const { name, clcDepartmentId, costCenters } = department;

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 5 }}
        onFinish={() =>
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleSubmit(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            })
        }
      >
        <Item name="name" label="Departamento" initialValue={name}>
          <Input prefix={<CreditCardFilled />} readOnly bordered={false} />
        </Item>
        <Item
          name="costCenters"
          label="Centro de costo"
          initialValue={costCenters.map((item) => item._id)}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Seleccione centros de costo"
            bordered={false}
          >
            {ccData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="clcDepartmentId"
          label="clcDepartmentId"
          initialValue={clcDepartmentId}
        >
          <Input
            placeholder="Ingrese id de la clínica"
            suffix={<EditOutlined />}
            bordered={false}
          />
        </Item>
        <Button htmlType="submit" block shape="round">
          Cambiar
        </Button>
      </Form>
    </>
  );
};

export default DepartmentForm;
