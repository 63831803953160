import React, { useState, useEffect } from "react";
import { ALL_FEATURE, CREATE_FEATURE, DELETE_FEATURE } from "../../Queries";
import CreateRecord from "./create";
import LoadingMask from "../../Components/LoadingMask";
import gStyle from "../GlobalScreens.module.css";
import Render from "./render";
import { showNotification } from "../../Helpers/notificationUtil";

import { Button, Input } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";

const FeatureScreen = () => {
  const [features, setFeatures] = useState([]);
  const [featuresFiltered, setFeaturesFiltered] = useState([]);
  const [creating, setCreating] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [visible, setVisible] = useState(false);

  const { loading, data, refetch } = useQuery(ALL_FEATURE, {
    fetchPolicy: "network-only",
  });
  const [createFeature] = useMutation(CREATE_FEATURE);
  const [deleteFeature] = useMutation(DELETE_FEATURE);

  const onCreate = async (values) => {
    const { name } = values;
    setLoadingCreate(true);
    try {
      await createFeature({
        variables: {
          input: {
            name,
          },
        },
      });
      showNotification(
        "success",
        "Característica creada",
        "Se ha creado el característica correctamente."
      );
      setVisible(false);
      setCreating(false);
      setLoadingCreate(false);
      refetch();
    } catch {
      showNotification(
        "error",
        "Error al crear característica",
        "El registro no se ha podido crear."
      );
      setLoadingCreate(false);
    }
  };

  const deleteRecord = async (id) => {
    setDeleteLoading(true);
    try {
      await deleteFeature({ variables: { id } });
      showNotification(
        "success",
        "Registro eliminado",
        "Se ha eliminado el registro correctamente."
      );
      setDeleteLoading(false);
      refetch();
    } catch {
      showNotification(
        "error",
        "Error al eliminar",
        "El registro no se puede eliminar, ya que, cuenta con reservas a su nombre."
      );
      setDeleteLoading(false);
    }
  };

  const handleSearch = (input) => {
    const filteredFeatures = features.filter((item) => {
      const searchableParams = ["name", "_id"];
      for (const param of searchableParams) {
        const field = _.get(item, `[${param}]`, "").toLowerCase();
        if (field.includes(input.toLowerCase())) return true;
      }
      return false;
    });
    setFeaturesFiltered(filteredFeatures);
  };

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) {
      setFeatures(data.AllFeatures);
      setFeaturesFiltered(data.AllFeatures);
    }
  }, [loading, data]);

  return (
    <div className={gStyle.mainContent}>
      <div className={gStyle.createContainer}>
        <Button
          loading={creating}
          shape="round"
          className={gStyle.createButton}
          type="primary"
          onClick={() => {
            setCreating(true);
            setVisible(true);
          }}
        >
          Crear Registro
        </Button>
        <Input.Search
          placeholder="Buscar en Caracteristicas de Box"
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
          onSearch={(e) => handleSearch(e)}
          style={{ width: "85%", margin: "0px" }}
        />
      </div>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        {deleteLoading && <LoadingMask />}
        <Render
          features={featuresFiltered}
          deleteRecord={deleteRecord}
          loading={loading}
        />
      </div>
      <CreateRecord
        visible={visible}
        loadingCreate={loadingCreate}
        onCreate={onCreate}
        onCancel={() => {
          setCreating(false);
          setVisible(false);
        }}
      />
    </div>
  );
};

export default FeatureScreen;
