import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FeatureForm from "./form";
import { UPDATE_FEATURE } from "../../../Queries";
import LoadingMask from "../../../Components/LoadingMask";
import { showNotification } from "../../../Helpers/notificationUtil";
import gStyles from "../../GlobalScreens.module.css";

import { Button, Card, List } from "antd";
import {
  EditOutlined,
  LeftCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";

const { Meta } = Card;

const Handler = ({ id, feature }) => {
  const { createdAt, name, updatedAt } = feature;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const created = moment(createdAt).format("L");
  const updated = moment(updatedAt).format("L");
  const [updateFeature] = useMutation(UPDATE_FEATURE);

  const handleSubmit = async (values) => {
    const { name } = values;
    setLoading(true);
    try {
      const response = await updateFeature({
        variables: {
          id,
          input: {
            name,
          },
        },
      });
      if (typeof response === "object" && response !== null) {
        showNotification(
          "success",
          "Característica Actualizado",
          "Se ha actualizado la característica correctamente."
        );
        setLoading(false);
        history.push("/feature");
      }
    } catch (err) {
      console.log("Error al modificar registro", err);
      showNotification(
        "error",
        "Error al actualizar característica",
        "El registro no se ha podido actualizar."
      );
      setLoading(false);
    }
  };

  const handleClick = () => {
    history.goBack();
  };

  return (
    <>
      <Button
        block
        shape="round"
        icon={<LeftCircleFilled />}
        onClick={handleClick}
      >
        Volver
      </Button>
      <br />
      <br />
      <Card
        title={name}
        style={{
          width: "100%",
          borderRadius: 10,
          cursor: "default",
          position: "relative",
        }}
        hoverable
      >
        {loading && <LoadingMask />}
        <Meta description={id} />
        <List
          bordered={false}
          size="small"
          footer={
            <div className={gStyles.dates}>
              <div className={gStyles.insideDates}>
                <PlusCircleOutlined />
                <span>{created}</span>
              </div>
              <div className={gStyles.insideDates}>
                <span>{updated}</span>
                <EditOutlined />
              </div>
            </div>
          }
        >
          <FeatureForm name={name} handleSubmit={handleSubmit} />
        </List>
      </Card>
    </>
  );
};

export default Handler;
