import React from 'react';
import { Route } from 'react-router-dom';
import updateScreen from './updateScreen';
import SpecialtyScreen from './SpecialtyScreen';

const indexProfessional = () => {
  return (
    <>
      <Route exact path="/specialty" component={SpecialtyScreen} />
      <Route exact path="/specialty/:id" component={updateScreen} />
    </>
  );
};

export default indexProfessional;
