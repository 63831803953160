import React, { useEffect, useState } from "react";
import Handler from "./handler";
import { COST_CENTER_BY_ID } from "../../../Queries";
import Loading from "../../../Components/Loading";
import gStyles from "../../GlobalScreens.module.css";

import { Empty } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router-dom";

const UpdateScreen = () => {
  const [costCenter, setCostCenter] = useState({});
  let { params } = useRouteMatch();
  let id = params.id;
  let content;

  const { loading, error, data } = useQuery(COST_CENTER_BY_ID, {
    variables: { id },
  });

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) {
      setCostCenter(data.CostCenterById);
    }
  }, [loading, data]);

  if (Object.keys(costCenter).length === 0) content = <Loading />;
  else if (error) content = <Empty />;
  else content = <Handler id={id} costCenter={costCenter} />;

  return <div className={gStyles.mainContent}>{content}</div>;
};

export default UpdateScreen;
