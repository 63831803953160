import { gql } from 'apollo-boost';

const ALL_PROFESSIONAL = gql`
  query {
    AllProfessionals {
      _id
      rut
      firstName
      lastName
      phone
      email
      code
      specialties {
        _id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const CREATE_PROFESSIONAL = gql`
  mutation CreateProfessional($input: createProfessionalInput!) {
    createProfessional(input: $input) {
      rut
      firstName
      lastName
      phone
      email
      code
      specialties {
        _id
      }
    }
  }
`;

const DELETE_PROFESSIONAL = gql`
  mutation DeleteProfessional($id: ID!) {
    deleteProfessional(_id: $id)
  }
`;

const PROFESSIONAL_BY_ID = gql`
  query ProfessionalById($id: ID!) {
    ProfessionalById(_id: $id) {
      _id
      rut
      firstName
      lastName
      phone
      email
      code
      specialties {
        _id
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_PROFESSIONAL = gql`
  mutation UpdateProfessional($id: ID!, $input: updateProfessionalInput!) {
    updateProfessional(_id: $id, input: $input) {
      rut
      firstName
      lastName
      phone
      email
      code
      specialties {
        _id
      }
    }
  }
`;

export {
  ALL_PROFESSIONAL,
  CREATE_PROFESSIONAL,
  DELETE_PROFESSIONAL,
  PROFESSIONAL_BY_ID,
  UPDATE_PROFESSIONAL,
};
