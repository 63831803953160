import Auth from '@aws-amplify/auth';
import AWS from 'aws-sdk';
// import temporaryPassword from './temporaryPassword';

const UserPoolId = String(process.env.REACT_APP_COGNITO_ADMIN_USER_POOL_ID);
const region = 'us-east-1';

const createCognitoUser = async (UserAttributes, email) => {
  const cognito = new AWS.CognitoIdentityServiceProvider({
    credentials: await Auth.currentCredentials(),
    region,
  });
  const params = {
    UserPoolId,
    Username: email.email.toLowerCase(),
    UserAttributes,
  };

  return cognito.adminCreateUser(params).promise();
};

export async function setCognitoPassword(Username, Password) {
  const cognito = new AWS.CognitoIdentityServiceProvider({
    credentials: await Auth.currentCredentials(),
    region,
  });

  const params = {
    UserPoolId,
    Permanent: true,
    Username,
    Password,
  };

  return cognito.adminSetUserPassword(params).promise();
}

export default async function user(email) {
  const User = [
    {
      Name: 'email',
      Value: email.email.toLowerCase(),
    },
    {
      Name: 'email_verified',
      Value: 'True',
    },
  ];

  const response = await createCognitoUser(User, email);

  return response.User;
}

export async function deleteUser(Username) {
  const cognito = new AWS.CognitoIdentityServiceProvider({
    credentials: await Auth.currentCredentials(),
    region,
  });

  const params = {
    UserPoolId,
    Username,
  };

  return cognito.adminDeleteUser(params).promise();
}

export async function getAllUsers() {
  const cognito = new AWS.CognitoIdentityServiceProvider({
    credentials: await Auth.currentCredentials(),
    region,
  });

  const params = {
    UserPoolId,
  };

  return cognito.listUsers(params).promise();
}

export async function getUser(Username) {
  const cognito = new AWS.CognitoIdentityServiceProvider({
    credentials: await Auth.currentCredentials(),
    region,
  });

  const params = {
    UserPoolId,
    Username,
  };

  const User = await cognito.adminGetUser(params).promise();

  return User;
}
