import React, { useEffect, useState } from "react";
import { ALL_PERIOD, ALL_DEPARTMENT, CREATE_PERIOD } from "../../Queries";
import CreateRecord from "./create";
import gStyle from "../GlobalScreens.module.css";
import Render from "./render";
import { showNotification } from "../../Helpers/notificationUtil";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button, Input } from "antd";
import _ from "lodash";
import moment from "moment";

const PeriodScreen = () => {
  const [periods, setPeriods] = useState([]);
  const [periodsFiltered, setPeriodsFiltered] = useState([]);
  const [creating, setCreating] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [visible, setVisible] = useState(false);

  const { loading, data, refetch } = useQuery(ALL_PERIOD, {
    fetchPolicy: "network-only",
  });
  const { data: departmentData } = useQuery(ALL_DEPARTMENT);
  const [createPeriod] = useMutation(CREATE_PERIOD);

  const onCreate = async (values) => {
    const { configuration, department, from } = values;
    setLoadingCreate(true);
    configuration.forEach((item) => {
      item.from = parseInt(item.from.format("HHmm"));
      item.to = parseInt(item.to.format("HHmm"));
    });

    try {
      await createPeriod({
        variables: {
          input: {
            configuration,
            department,
            from: from.utc().startOf("month"),
          },
        },
      });
      showNotification(
        "success",
        "Período creado",
        "Se ha creado período correctamente."
      );
      setVisible(false);
      setCreating(false);
      setLoadingCreate(false);
      refetch();
    } catch (err) {
      console.log("ERROR AL EJECUTAR BLOQUE DE CREATERECIRD ", err);
      showNotification(
        "error",
        "Error al crear período",
        "Error al crear período, contacte al centro técnico."
      );
      setLoadingCreate(false);
    }
  };

  const handleSearch = (input) => {
    const filteredPeriods = periods.filter((item) => {
      const searchableParams = ["_id", "to", "department.name"];
      for (const param of searchableParams) {
        if (param === "to") {
          // control to
          if (item.to === null) {
            if ("vigente".includes(input.toLowerCase())) return true;
          } else {
            if (moment(item.to).format("L").includes(input.toLowerCase())) {
              return true;
            }
          }
        } else if (param === "department.name") {
          if (item.department === null) {
            if ("por defecto".includes(input.toLowerCase())) return true;
          } else {
            const field = _.get(item, `[${param}]`, "").toLowerCase();
            if (field.includes(input.toLowerCase())) return true;
          }
        } else {
          const field = _.get(item, `[${param}]`, "").toLowerCase();
          if (field.includes(input.toLowerCase())) return true;
        }
      }
      return false;
    });
    setPeriodsFiltered(filteredPeriods);
  };

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) {
      setPeriods(data.AllPeriods);
      setPeriodsFiltered(data.AllPeriods);
    }
  }, [loading, data]);

  return (
    <div className={gStyle.mainContent}>
      <div className={gStyle.createContainer}>
        <Button
          loading={creating}
          shape="round"
          className={gStyle.createButton}
          type="primary"
          onClick={() => {
            setCreating(true);
            setVisible(true);
          }}
        >
          Crear Registro
        </Button>
        <Input.Search
          placeholder="Buscar en Periodos"
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
          onSearch={(e) => handleSearch(e)}
          style={{ width: "85%", margin: "0px" }}
        />
      </div>
      <Render periods={periodsFiltered} loading={loading} />
      <CreateRecord
        visible={visible}
        loadingCreate={loadingCreate}
        onCreate={onCreate}
        onCancel={() => {
          setCreating(false);
          setVisible(false);
        }}
        departmentData={departmentData ? departmentData.AllDepartments : []}
      />
    </div>
  );
};

export default PeriodScreen;
