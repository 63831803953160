import React from 'react';
import { Route } from 'react-router-dom';
import updateScreen from './updateScreen';
import CostCenterScreen from './CostCenterScreen';

const indexCostCenter = () => {
  return (
    <>
      <Route exact path="/costCenter" component={CostCenterScreen} />
      <Route exact path="/costCenter/:id" component={updateScreen} />
    </>
  );
};

export default indexCostCenter;
