import React from 'react';
import { Route } from 'react-router-dom';
import updateScreen from './updateScreen';
import DepartmentScreen from './DepartmentScreen';

const indexDepartment = () => {
  return (
    <>
      <Route exact path="/department" component={DepartmentScreen} />
      <Route exact path="/department/:id" component={updateScreen} />
    </>
  );
};

export default indexDepartment;
