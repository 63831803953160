import React, { useEffect, useState } from "react";
import computePeriodData from "../../../Helpers/periodUtils";
import gStyles from "../../GlobalScreens.module.css";
import styles from "../PeriodScreen.module.css";

import { Card, Col, List, Row, Tag } from "antd";
import moment from "moment";

const { Meta } = Card;
const { Item } = List;

const Handler = ({ item }) => {
  const [period, setPeriod] = useState([]);
  const { _id, from, department, to } = item;

  useEffect(() => {
    setPeriod(computePeriodData(item ? item : null));
  }, [item]);

  return (
    <>
      <Card
        bodyStyle={{ padding: 0 }}
        className={gStyles.cardStyle}
        style={{ height: "400px" }}
        hoverable
        title="Periodo"
      >
        <Meta description={_id} style={{ padding: 10 }} />
        <List
          bordered={false}
          size="small"
          header={
            <Col offset={1}>
              <Tag color="geekblue" className={styles.validity}>
                Desde: {moment.utc(from).format("DD [de] MMMM YYYY")}
              </Tag>
            </Col>
          }
          footer={
            <Row>
              <Col offset={1}>
                {to ? (
                  <Tag color="red" className={styles.validity}>
                    <strong>Vencimiento: {moment(to).format("L")}</strong>
                  </Tag>
                ) : (
                  <Tag color="#3b5999" className={styles.validity}>
                    <strong>Vigente</strong>
                  </Tag>
                )}
              </Col>
            </Row>
          }
        >
          {period.length > 0
            ? period.map((group, index) => (
                <Item className={styles.listBackground} key={index}>
                  <Row>
                    {group.map((period, i) => (
                      <Col key={i}>
                        <span>
                          <Tag color="blue">{period.name}: </Tag>
                          {period.times}
                        </span>
                      </Col>
                    ))}
                  </Row>
                </Item>
              ))
            : null}
          {department ? (
            <Item>
              <Tag color="cyan">{department.name}</Tag>
            </Item>
          ) : (
            <Item>
              <Tag color="geekblue">Por defecto</Tag>
            </Item>
          )}
        </List>
      </Card>
    </>
  );
};

export default Handler;
