import React, { useState } from 'react'
import drawExcel from '../../../../Helpers/drawExcel'
import { RESERVE_TO_EXCEL } from '../../../../Queries'
import styles from './ExportToExcel.module.css'

import { Button } from 'antd'
import { useLazyQuery } from '@apollo/react-hooks'

const ExportToExcel = ({ date }) => {
  const [loading, setLoading] = useState(false)
  const [loadReserves] = useLazyQuery(RESERVE_TO_EXCEL, {
    onCompleted: async data => {
      await exportFile(data)
    }
  })

  const exportFile = async (data) => {
    try {
      await drawExcel(data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <Button
      loading={loading}
      onClick={async () => {
        setLoading(true)
        loadReserves({
          variables: {
            date
          }
        })
      }}
      className={styles.excelButton}
      type="primary"
      shape="round"
    >
      Exportar a Excel
    </Button>
  )
}

export default ExportToExcel
