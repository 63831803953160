import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DepartmentForm from "./form";
import { UPDATE_DEPARTMENT } from "../../../Queries";
import { showNotification } from "../../../Helpers/notificationUtil";
import LoadingMask from "../../../Components/LoadingMask";
import gStyles from "../../GlobalScreens.module.css";

import { Button, Card, List } from "antd";
import {
  EditOutlined,
  LeftCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";

const { Meta } = Card;

const Handler = ({ id, department, ccData }) => {
  const { createdAt, name, updatedAt } = department;
  const history = useHistory();
  const created = moment(createdAt).format("L");
  const updated = moment(updatedAt).format("L");
  const [loading, setLoading] = useState(false);
  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT);

  const handleSubmit = async (values) => {
    const { name, clcDepartmentId, costCenters } = values;
    setLoading(true);
    try {
      const response = await updateDepartment({
        variables: {
          id,
          input: {
            name,
            clcDepartmentId,
            costCenters,
          },
        },
      });
      if (typeof response === "object" && response !== null) {
        showNotification(
          "success",
          "Departamento Actualizado",
          "Se ha actualizado el departamento correctamente."
        );
        setLoading(false);
        history.push("/department");
      }
    } catch (err) {
      showNotification(
        "error",
        "Error al actualizar departamento",
        "El registro no se ha podido actualizar."
      );
      setLoading(false);
      // history.push("/department");
    }
  };

  const handleClick = () => {
    history.goBack();
  };

  return (
    <>
      <Button
        block
        shape="round"
        icon={<LeftCircleFilled />}
        onClick={handleClick}
      >
        Volver
      </Button>
      <br />
      <br />
      <Card
        title={name}
        className={gStyles.createCardStyle}
        style={{ position: "relative" }}
        hoverable
      >
        {loading && <LoadingMask />}
        <Meta description={id} />

        <List
          bordered={false}
          size="small"
          footer={
            <div className={gStyles.dates}>
              <div className={gStyles.insideDates}>
                <PlusCircleOutlined />
                <span>{created}</span>
              </div>
              <div className={gStyles.insideDates}>
                <span>{updated}</span>
                <EditOutlined />
              </div>
            </div>
          }
        >
          <DepartmentForm
            ccData={ccData}
            department={department}
            handleSubmit={handleSubmit}
          />
        </List>
      </Card>
    </>
  );
};

export default Handler;
