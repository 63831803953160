import React from 'react';
import Logo from '../../../../Assets/Logo';
import styles from './CustomHeaderStyles.module.css';

import { Button, Col, Dropdown, Layout, Menu, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

const { Header } = Layout;
const { Item } = Menu;

const CustomHeader = ({ signOut }) => {
  const menu = (
    <Menu>
      <Item key="0">
        <Button type="text" onClick={() => signOut()}>
          Logout
        </Button>
      </Item>
    </Menu>
  );

  return (
    <Header className={styles.customHeader}>
      <Row style={{ maxHeight: '8vh' }}>
        <Col md={18} offset={1} style={{ maxHeight: '8vh' }}>
          <Row style={{ maxHeight: '8vh' }}>
            <Col md={4} style={{ maxHeight: '8vh' }}>
              <div className={styles.logo} style={{ maxHeight: '8vh' }}>
                <NavLink to="/">
                  <Logo width="180px" />
                </NavLink>
              </div>
            </Col>

            <Col md={8} offset={6}>
              <h3 className={styles.headerTitle}>
                Arriendo de bloques - Maintainer
              </h3>
            </Col>

            <Col md={4} offset={2}>
              <Dropdown overlay={menu} trigger={['click']}>
                <div className={styles.dropdownUserMenuItem}>
                  <a className="ant-dropdown-link" href="#/">
                    <div className={styles.dropdownUserMenuText}>
                      <h4>Admin</h4>
                      <h5>Perfil Usuario</h5>
                    </div>
                    <DownOutlined className={styles.dropdownUserMenuIcon} />
                  </a>
                </div>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

export default CustomHeader;
