import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CostCenterform from "./form";
import { UPDATE_COST_CENTER } from "../../../Queries";
import LoadingMask from "../../../Components/LoadingMask";
import { showNotification } from "../../../Helpers/notificationUtil";
import gStyles from "../../GlobalScreens.module.css";

import { Button, Card, List } from "antd";
import {
  EditOutlined,
  LeftCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";

const { Meta } = Card;

const Handler = ({ id, costCenter }) => {
  const { createdAt, name, updatedAt, dualMorning } = costCenter;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const created = moment(createdAt).format("L");
  const updated = moment(updatedAt).format("L");
  const [updateCostCenter] = useMutation(UPDATE_COST_CENTER);

  const handleSubmit = async (values) => {
    const { clcCostCenterId, name } = values;
    setLoading(true);
    try {
      const response = await updateCostCenter({
        variables: {
          id,
          input: {
            name,
            clcCostCenterId,
            dualMorning,
          },
        },
      });
      if (typeof response === "object" && response !== null) {
        showNotification(
          "success",
          "Centro de costo Actualizado",
          "Se ha actualizado el centro de costo correctamente."
        );
        setLoading(false);
        history.push("/costcenter");
      }
    } catch (err) {
      console.log("Error al modificar registro", err);
      showNotification(
        "error",
        "Error al actualizar centro de costo",
        "El registro no se ha podido actualizar."
      );
      setLoading(false);
    }
  };

  const handleClick = () => {
    history.goBack();
  };

  return (
    <>
      <Button
        block
        shape="round"
        icon={<LeftCircleFilled />}
        onClick={handleClick}
      >
        Volver
      </Button>
      <br />
      <br />
      <Card
        title={`Centro de costo  '${name}'`}
        className={gStyles.createCardStyle}
        style={{ position: "relative" }}
        hoverable
      >
        {loading && <LoadingMask />}
        <Meta description={id} />
        <List
          bordered={false}
          size="small"
          footer={
            <div className={gStyles.dates}>
              <div className={gStyles.insideDates}>
                <PlusCircleOutlined />
                <span>{created}</span>
              </div>
              <div className={gStyles.insideDates}>
                <span>{updated}</span>
                <EditOutlined />
              </div>
            </div>
          }
        >
          <CostCenterform costCenter={costCenter} handleSubmit={handleSubmit} />
        </List>
      </Card>
    </>
  );
};

export default Handler;
