

const translateRole = role => {
  switch (role){
    case 'NURSE':
      return 'Enfermera'
    case 'SUPERVISOR':
      return 'Supervisor'
    default:
      return 'Sin rol'
  }
}

const roleColor = role => {
  switch (role){
    case 'NURSE':
      return 'cyan'
    case 'SUPERVISOR':
      return 'green'
    default:
      return 'red'
  }
}

export {translateRole, roleColor}