import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Screens from './Screens';
import Root from './Root';
import 'antd/dist/antd.css';
import { ApolloApp } from './Providers';
import { Client } from './ApolloClient';

export default function App() {
  return (
    <ApolloApp client={Client}>
      <Router>
        <Route component={Root}>{Screens}</Route>
      </Router>
    </ApolloApp>
  );
}
