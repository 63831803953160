import React from 'react';

import { Button, Form, Input } from 'antd';
import { EditOutlined, FolderOpenFilled } from '@ant-design/icons';

const { Item } = Form;

const FeatureForm = ({ name, handleSubmit }) => {
  const [form] = Form.useForm();

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 5 }}
        onFinish={() =>
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              handleSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            })
        }
      >
        <Item name="name" label="Característica" initialValue={name}>
          <Input
            bordered={false}
            prefix={<FolderOpenFilled />}
            suffix={<EditOutlined />}
          />
        </Item>
        <Button htmlType="submit" block shape="round">
          Cambiar
        </Button>
      </Form>
    </>
  );
};

export default FeatureForm;
