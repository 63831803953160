import React, { useState } from "react";
import LoadingMask from "../../../Components/LoadingMask";
import { Modal, DatePicker, Form, Select } from "antd";
import FormList from "./formList";

import moment from "moment";
import "moment/locale/es";

moment.locale("es");
const { Item } = Form;
const { Option } = Select;

const CreateRecord = (props) => {
  const [start, setStart] = useState([]);
  const [end, setEnd] = useState([]);
  const { departmentData, onCancel, onCreate, visible, loadingCreate } = props;
  const [form] = Form.useForm();

  const handleEndHour = (time) => {
    let arr = [];
    for (let i = 0; i <= moment(time).hour(); i++) {
      arr.push(i);
    }
    return setEnd(arr);
  };

  const handleStartHour = (time) => {
    let arr = [];
    for (let i = 0; i <= moment(time).hour(); i++) {
      arr.push(i);
    }
    return setStart(arr);
  };

  return (
    <Modal
      visible={visible}
      title="Ingrese datos"
      okText="Confirmar"
      cancelText="Cancelar"
      style={{ position: "relative" }}
      onCancel={() => {
        onCancel();
        setEnd([]);
        setStart([]);
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            setEnd([]);
            setStart([]);
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {loadingCreate && <LoadingMask />}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Item
          name="from"
          label="Vigencia desde"
          rules={[{ required: true, message: "Indique la fecha de vigencia" }]}
        >
          <DatePicker
            bordered={false}
            disabledDate={(current) =>
              current && current < moment().endOf("day")
            }
            format="MMMM YYYY"
            initialValues={moment().add(1, "months")}
            inputReadOnly
            placeholder={`${moment().add(1, "months").format("MMMM YYYY")}`}
            picker="month"
            style={{ width: "100%" }}
          />
        </Item>
        <p>Configuración: </p>
        <FormList
          end={end}
          handleEndHour={handleEndHour}
          handleStartHour={handleStartHour}
          start={start}
        />
        <Item name="department" label="Departamento">
          <Select
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Seleccione Departamento"
          >
            {departmentData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateRecord;
