import React, { useEffect } from 'react';
import CustomHeader from './Component/CustomHeader';
import MainContainer from './Component/Main';

import { Auth } from 'aws-amplify';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';

const AppLayout = ({ history, children }) => {
  useEffect(() => {
    Auth.currentSession()
      .then(data => {})
      .catch(err => {
        console.log(err);
        return history.push('/login');
      });
  }, [history]);

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      return history.push('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <Layout
      style={{
        height: '100vh',
      }}
    >
      <CustomHeader signOut={signOut} />
      <MainContainer props={children} />
    </Layout>
  );
};

export default withRouter(AppLayout);
