import React from "react";
import LoadingMask from "../../../../Components/LoadingMask";
import styles from "../../AdminScreen.module.css";

import { Button, Card, Divider, Form, Input } from "antd";

const { Item } = Form;

const ChangePassword = (props) => {
  const { assignPassword, loading } = props;
  const [form] = Form.useForm();

  return (
    <>
      <Card
        title="Cambiar contraseña"
        style={{ position: "relative" }}
        className={styles.insideCardForm}
        hoverable
      >
        {loading && <LoadingMask />}
        <Form
          form={form}
          layout="vertical"
          onFinish={() =>
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                assignPassword(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              })
          }
        >
          <Item
            name="password"
            label="Nueva contraseña"
            rules={[
              {
                required: true,
                message: "Por favor ingrese una contraseña válida.",
              },
            ]}
          >
            <Input.Password placeholder="Contraseña" />
          </Item>
          <Divider />
          <Button htmlType="submit" block shape="round">
            Cambiar
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default ChangePassword;
