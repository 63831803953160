import React from "react";
import Handler from "./handler";
import Loading from "../../../Components/Loading";
import styles from "../AdminScreen.module.css";

import { Empty, List } from "antd";

const { Item } = List;

const Render = ({ admins, deleteRecord, loading }) => {
  if (loading) return <Loading />;

  return Object.keys(admins).length > 0 ? (
    <div className={styles.listContainer}>
      <List
        pagination={{ pageSize: 8 }}
        grid={{ gutter: 16, column: 4 }}
        dataSource={Object.values(admins)}
        renderItem={(item) => (
          <Item>
            <Handler
              key={item[Object.keys(item)[0]]._id}
              item={item[Object.keys(item)[0]]}
              deleteRecord={deleteRecord}
            />
          </Item>
        )}
      />
    </div>
  ) : (
    <Empty />
  );
};

export default Render;
