import React from "react";
import gStyles from "../../GlobalScreens.module.css";
import styles from "../BoxScreen.module.css";

import { Card, Col, Button, List, Modal, Tag, Row } from "antd";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";

const { Meta } = Card;
const { Item } = List;
const { confirm } = Modal;

const Handler = (props) => {
  const { item, deleteRecord } = props;
  const {
    _id,
    costCenter,
    clcBoxId,
    createdAt,
    department,
    features,
    headquarters,
    name,
    squareMeters,
    updatedAt,
  } = item;
  const created = moment(createdAt).format("L");
  const updated = moment(updatedAt).format("L");
  const history = useHistory();
  let { url } = useRouteMatch();

  const showDeleteConfirm = () => {
    confirm({
      title: "¿Estas seguro que deseas eliminar este registro?",
      icon: <ExclamationCircleOutlined />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteRecord(_id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleClick = (id) => {
    history.push(`${url}/${id}`);
  };

  return (
    <>
      <Card
        bodyStyle={{ height: "90%", padding: "8px 0px" }}
        className={gStyles.cardStyle}
        style={{ height: "480px" }}
        hoverable
        title={name}
      >
        <Meta description={_id} style={{ padding: 10 }} />
        <List
          bordered={false}
          onClick={() => handleClick(_id)}
          size="small"
          footer={
            <div className={gStyles.dates}>
              <div className={gStyles.insideDates}>
                <PlusCircleOutlined />
                <span>{created}</span>
              </div>
              <div className={gStyles.insideDates}>
                <span>{updated}</span>
                <EditOutlined />
              </div>
            </div>
          }
        >
          {clcBoxId ? (
            <Item>clcBoxId: {clcBoxId}</Item>
          ) : (
            <Item>clcBoxId: ~~</Item>
          )}
          {costCenter ? (
            <Item>
              Centro de costo:{" "}
              <span className={styles.costCenter}>{costCenter.name}</span>
            </Item>
          ) : (
            <Item>Centro de costo: ~~</Item>
          )}
          {department ? (
            <Item>
              Departamento: <Tag color="#707ef7">{department.name}</Tag>
            </Item>
          ) : (
            <Item>Departamento: ~~</Item>
          )}
          {headquarters ? (
            <Item>
              Sede: <strong className={styles.building}>{headquarters}</strong>
            </Item>
          ) : (
            <Item>Sede: ~~</Item>
          )}
          {squareMeters ? (
            <Item>Metros cuadrados: {squareMeters}</Item>
          ) : (
            <Item>Metros cuadrados: ~~</Item>
          )}
          {features.length > 0 ? (
            <Item>
              Features:{" "}
              {features.map((feat) => (
                <Tag key={feat._id} color="#87d068">
                  {feat.name}
                </Tag>
              ))}
            </Item>
          ) : (
            <br />
          )}
        </List>
        <Row justify="end" align="bottom">
          <Col sm={6} style={{ margin: 10 }}>
            <Button
              type="primary"
              danger
              onClick={showDeleteConfirm}
              className={gStyles.deleteButton}
            >
              Borrar
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Handler;
