import React from "react";
import LoadingMask from "../../../Components/LoadingMask";
import { Modal, Form, Input, Select } from "antd";

const { Item } = Form;
const { Option } = Select;

const CreateRecord = (props) => {
  const { visible, onCreate, onCancel, loadingCreate } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Ingrese datos"
      okText="Confirmar"
      cancelText="Cancelar"
      style={{ position: "relative" }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {loadingCreate && <LoadingMask />}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Item
          name="costCenter"
          label="Centro de costo"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un Centro de costo",
            },
          ]}
        >
          <Input placeholder="Ingrese centro de costo" />
        </Item>
        <Item
          name="dualMorning"
          label="Tipo dualmorning"
          rules={[
            {
              required: true,
              message: "Por favor ingrese tipo dualMorning",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Seleccionar dualmorning"
          >
            <Option value={false}>sin dualmorning</Option>
            <Option value={true}>con dualmorning</Option>
          </Select>
        </Item>
        <Item name="clcCostCenterId" label="clcCostCenterId">
          <Input placeholder="Ingrese clcCostCenterId" />
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateRecord;
