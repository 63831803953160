import React from 'react';
import { Route } from 'react-router-dom';
import BoxScreen from './BoxScreen';
import updateScreen from './updateScreen';

const indexBox = () => {
  return (
    <>
      <Route exact path="/box" component={BoxScreen} />
      <Route exact path="/box/:id" component={updateScreen} />
    </>
  );
};

export default indexBox;
