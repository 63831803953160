import React from 'react';
import { Route } from 'react-router-dom';
import updateScreen from './updateScreen';
import FeatureScreen from './FeatureScreen';

const indexFeature = () => {
  return (
    <>
      <Route exact path="/feature" component={FeatureScreen} />
      <Route exact path="/feature/:id" component={updateScreen} />
    </>
  );
};

export default indexFeature;
