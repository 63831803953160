import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Roles from "./forms/roles";
import ChangePassword from "./forms/changePassword";
import { setCognitoPassword } from "../../../Helpers/cognito";
import rutFormat from "../../../Helpers/rutUtil";
import { showNotification } from "../../../Helpers/notificationUtil";
import styles from "../AdminScreen.module.css";
import gStyles from "../../GlobalScreens.module.css";

import { Button, Card, List, Tag } from "antd";
import {
  EditOutlined,
  LeftCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Meta } = Card;
const { Item } = List;

const Handler = (props) => {
  const [loadingCPW, setLoadingCPW] = useState(false);
  const { AdminById, email } = props;
  const {
    _id,
    createdAt,
    firstName,
    lastName,
    rut,
    sub,
    role,
    updatedAt,
  } = AdminById;
  const history = useHistory();
  const created = moment(createdAt).format("L");
  const updated = moment(updatedAt).format("L");

  const assignPassword = async (values) => {
    const { password } = values;
    setLoadingCPW(true);
    try {
      await setCognitoPassword(sub, password);
      showNotification(
        "success",
        "Cambio de clave exitoso",
        "Se ha cambiado la clave correctamente."
      );
      setLoadingCPW(false);
    } catch (err) {
      showNotification(
        "error",
        "Error al cambiar clave",
        "Ha habido un error en el cambio de clave."
      );
      console.log(err);
      setLoadingCPW(false);
    }
  };

  return (
    <>
      <Button
        block
        icon={<LeftCircleFilled />}
        onClick={() => history.goBack()}
        shape="round"
      >
        Volver
      </Button>
      <br />
      <br />
      <Card
        bodyStyle={{ padding: 0 }}
        className={gStyles.createCardStyle}
        hoverable
        title={`${firstName} ${lastName}`}
      >
        <Meta description={sub} style={{ padding: 10 }} />
        <List
          bordered={false}
          size="small"
          footer={
            <div className={gStyles.dates}>
              <div className={gStyles.insideDates}>
                <PlusCircleOutlined />
                <span>{created}</span>
              </div>
              <div className={gStyles.insideDates}>
                <span>{updated}</span>
                <EditOutlined />
              </div>
            </div>
          }
        >
          <Item>Rut: {rutFormat(rut)}</Item>
          {email ? (
            <Item>
              Correo:{" "}
              <Tag color="blue" className={styles.emailForm}>
                {email}
              </Tag>
            </Item>
          ) : (
            <Item>Correo: </Item>
          )}
        </List>
      </Card>
      <div className={styles.updateForm}>
        <Roles id={_id} role={role} />
        <ChangePassword assignPassword={assignPassword} loading={loadingCPW} />
      </div>
    </>
  );
};

export default Handler;
