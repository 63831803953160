import React, { useState } from "react";

import { Button, Form, Input, InputNumber, Select, Switch } from "antd";
import { CarryOutOutlined, EditOutlined } from "@ant-design/icons";

const { Item } = Form;
const { Option } = Select;

const BoxForm = ({
  box,
  costCenterData,
  departmentData,
  ftData,
  handleSubmit,
}) => {
  const [form] = Form.useForm();
  const {
    clcBoxId,
    isOffice,
    costCenter,
    department,
    features,
    headquarters,
    squareMeters,
    name,
  } = box;
  const [isOff, setIsOff] = useState(isOffice);
  const labelItemIsOffice = isOff ? "Es una oficina" : "Es un box";

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 5 }}
        onFinish={() =>
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              handleSubmit(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            })
        }
      >
        <Item name="name" label={isOff ? "Oficina" : "Box"} initialValue={name}>
          <Input prefix={<CarryOutOutlined />} bordered={false} />
        </Item>
        <Item name="clcBoxId" label="clcBoxId" initialValue={clcBoxId}>
          <Input
            placeholder="Ingrese id de la clínica"
            suffix={<EditOutlined />}
            bordered={false}
          />
        </Item>
        <Item
          name="costCenter"
          label="Centro de Costo"
          initialValue={costCenter._id}
        >
          <Select
            placeholder="Seleccione centro de costo"
            disabled
            bordered={false}
          >
            {costCenterData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="department"
          label="Departamento"
          initialValue={department._id}
        >
          <Select
            placeholder="Seleccione departamento/servicio"
            disabled
            bordered={false}
          >
            {departmentData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="features"
          label="Características"
          initialValue={Object.values(features).map((item) => item._id)}
        >
          <Select
            mode="multiple"
            tokenSeparators={[","]}
            placeholder="Seleccione una característica"
            bordered={false}
          >
            {ftData.map((item) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="headquarters" label="Sede" initialValue={headquarters}>
          <Select
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Indique sede"
            bordered={false}
          >
            <Option value="CHICUREO">Sede Chicureo</Option>
            <Option value="ESTORIL">Sede Estoril</Option>
            <Option value="PEÑALOLÉN">Sede Peñalolén</Option>
          </Select>
        </Item>
        <Item
          name="squareMeters"
          label="Metros cuadrados"
          initialValue={squareMeters}
        >
          <InputNumber min={1} max={100} />
        </Item>
        <Item
          name="isOffice"
          label={labelItemIsOffice}
          valuePropName="checked"
          initialValue={isOffice}
        >
          <Switch onChange={() => setIsOff(!isOff)} style={{ width: "60px" }} />
        </Item>
        <Button htmlType="submit" block shape="round">
          Cambiar
        </Button>
      </Form>
    </>
  );
};

export default BoxForm;
