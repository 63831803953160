import React from 'react'

import { Form, TimePicker, Input, Space } from 'antd'

const { Item } = Form

const FormList = props => {
  const { end, handleEndHour, handleStartHour, start } = props

  const names = ['M1', 'M2', 'T1', 'T2']

  return (
    <>
      <div>
        {
          names.map((name, index) => (
            <Space
              style={{ display: 'flex', marginBottom: 8 }}
              align="start"
              key={index}
            >
              <Item
                name={['configuration', index, 'name']}
                initialValue={name}
                rules={[
                  { required: true, message: 'Nombre de bloque es obligatorio' },
                ]}
              >
                <Input disabled/>
              </Item>
              <Item
                name={['configuration', index, 'from']}
                rules={[
                  { required: true, message: 'Hora inicial es requerida' },
                ]}
              >
                <TimePicker
                  bordered={false}
                  disabledHours={() => (start.length < 1 ? 0 : start)}
                  format="HH:mm"
                  onChange={time => handleEndHour(time)}
                  placeholder="Hora inicial"
                  showNow={false}
                />
              </Item>
              <Item
                name={['configuration', index, 'to']}
                rules={[
                  { required: true, message: 'Hora final es requerida' },
                ]}
              >
                <TimePicker
                  bordered={false}
                  disabledHours={() => end}
                  format="HH:mm"
                  onChange={time => handleStartHour(time)}
                  placeholder="Hora final"
                  showNow={false}
                />
              </Item>

            </Space>
          ))
        }
      </div>

    </>
  )
}

export default FormList
