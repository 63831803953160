import { gql } from 'apollo-boost';

const ALL_PERIOD = gql`
  query {
    AllPeriods {
      _id
      configuration {
        name
        from
        to
      }
      department {
        _id
        name
      }
      from
      to
    }
  }
`;

const CREATE_PERIOD = gql`
  mutation CreatePeriod($input: createPeriodInput!) {
    createPeriod(input: $input) {
      configuration {
        name
        from
        to
      }
      department {
        _id
      }
      from
      to
    }
  }
`;

export { ALL_PERIOD, CREATE_PERIOD };
