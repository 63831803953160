import React from "react";
import gStyles from "../../GlobalScreens.module.css";
import { roleColor, translateRole } from "../../../Helpers/roleUtils";
import rutFormat from "../../../Helpers/rutUtil";
import styles from "../AdminScreen.module.css";

import { Card, Col, Button, List, Modal, Row, Tag } from "antd";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useHistory, useRouteMatch } from "react-router-dom";

const { Meta } = Card;
const { Item } = List;
const { confirm } = Modal;

const Handler = (props) => {
  const { item, deleteRecord } = props;

  const {
    _id,
    createdAt,
    email,
    firstName,
    lastName,
    rut,
    sub,
    updatedAt,
    role,
  } = item;
  const created = moment(createdAt).format("L");
  const updated = moment(updatedAt).format("L");
  const history = useHistory();
  let { url } = useRouteMatch();

  const handleClick = (id) => {
    history.push(`${url}/${id}`);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "¿Estas seguro que deseas eliminar este registro?",
      icon: <ExclamationCircleOutlined />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteRecord(_id, sub);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Card
        bodyStyle={{ height: "90%", padding: "8px 0px" }}
        className={gStyles.cardStyle}
        style={{ height: "400px" }}
        hoverable
        title={`${firstName} ${lastName}`}
      >
        <div onClick={() => handleClick(_id)}>
          <Row className={styles.renderCardHead}>
            <Col span={18}>
              <Meta description={_id} style={{ padding: 10 }} />
            </Col>
            <Col span={6} className={styles.renderCardHeadTag}>
              <Tag color={roleColor(role)}>{translateRole(role)}</Tag>
            </Col>
          </Row>
          <Row className={styles.renderCardList}>
            <Col span={24}>
              <List
                size="small"
                footer={
                  <>
                    <div className={gStyles.dates}>
                      <div className={gStyles.insideDates}>
                        <PlusCircleOutlined />
                        <span>{created}</span>
                      </div>
                      <div className={gStyles.insideDates}>
                        <span>{updated}</span>
                        <EditOutlined />
                      </div>
                    </div>
                  </>
                }
              >
                <Item>{sub || null}</Item>
                <Item>{rutFormat(rut) || null}</Item>
                <Item>
                  <Tag color="blue" className={styles.emailForm}>
                    {email || null}
                  </Tag>
                </Item>
              </List>
            </Col>
          </Row>
        </div>
        <Row justify="end" align="bottom">
          <Col sm={6} style={{ margin: 10 }}>
            <Button
              type="primary"
              danger
              onClick={showDeleteConfirm}
              className={gStyles.deleteButton}
            >
              Borrar
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Handler;
