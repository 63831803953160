import React from 'react';

import { Button, Form, Input, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const { Item } = Form;
const { Option } = Select;

const ProfessionalForm = ({ specialtyData, handleSubmit, professional }) => {
  console.log(professional);
  console.log(specialtyData);
  const [form] = Form.useForm();
  const { specialties, email, phone, rut } = professional;

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 5 }}
        onFinish={() =>
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              handleSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            })
        }
      >
        <Item name="email" label="Correo electrónico" initialValue={email}>
          <Input suffix={<EditOutlined />} bordered={false} />
        </Item>
        <Item name="phone" label="Fono" initialValue={phone}>
          <Input suffix={<EditOutlined />} bordered={false} />
        </Item>
        <Item name="rut" label="Rut" initialValue={rut}>
          <Input suffix={<EditOutlined />} bordered={false} />
        </Item>
        <Item
          name="specialties"
          label="Especialidades"
          initialValue={specialties.map(item => item._id)}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            tokenSeparators={[',']}
            placeholder="Seleccione especialidad"
            bordered={false}
          >
            {specialtyData.map(item => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Button htmlType="submit" block shape="round">
          Cambiar
        </Button>
      </Form>
    </>
  );
};

export default ProfessionalForm;
