import React, { useEffect, useState } from "react";
import Handler from "./handler";
import {
  ALL_COST_CENTER,
  ALL_DEPARTMENT,
  ALL_FEATURE,
  BOX_BY_ID,
} from "../../../Queries";
import Loading from "../../../Components/Loading";
import gStyles from "../../GlobalScreens.module.css";

import { Empty } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { useRouteMatch } from "react-router-dom";

const UpdateScreen = () => {
  const [box, setBox] = useState({});
  let { params } = useRouteMatch();
  let id = params.id;
  let content;

  const { loading, error, data } = useQuery(BOX_BY_ID, {
    variables: { id },
  });
  const { data: costCenterData } = useQuery(ALL_COST_CENTER);
  const { data: departmentData } = useQuery(ALL_DEPARTMENT);
  const { data: ftData } = useQuery(ALL_FEATURE);

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) {
      setBox(data.BoxById);
    }
  }, [loading, data]);

  if (Object.keys(box).length === 0) content = <Loading />;
  else if (error) content = <Empty />;
  else
    content = (
      <Handler
        id={id}
        box={box}
        costCenterData={costCenterData ? costCenterData.AllCostCenters : []}
        departmentData={departmentData ? departmentData.AllDepartments : []}
        ftData={ftData ? ftData.AllFeatures : []}
      />
    );

  return <div className={gStyles.mainContent}>{content}</div>;
};

export default UpdateScreen;
