import React, { useEffect, useState } from "react";
import {
  ALL_DEPARTMENT,
  ALL_COST_CENTER,
  CREATE_DEPARTMENT,
  DELETE_DEPARTMENT,
} from "../../Queries";
import CreateRecord from "./create";
import LoadingMask from "../../Components/LoadingMask";
import gStyle from "../GlobalScreens.module.css";
import Render from "./render";
import { showNotification } from "../../Helpers/notificationUtil";

import { Button, Input } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";

const DepartmentScreen = () => {
  const [departments, setDepartments] = useState([]);
  const [departmentsFiltered, setDeptartmentsFiltered] = useState([]);
  const [creating, setCreating] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [visible, setVisible] = useState(false);

  const { loading, data, refetch } = useQuery(ALL_DEPARTMENT, {
    fetchPolicy: "network-only",
  });
  const [createDepartment] = useMutation(CREATE_DEPARTMENT);
  const [deleteDepartment] = useMutation(DELETE_DEPARTMENT);
  const { data: ccData } = useQuery(ALL_COST_CENTER);

  const onCreate = async (values) => {
    const { name, costCenters, clcDepartmentId } = values;
    setLoadingCreate(true);
    try {
      await createDepartment({
        variables: {
          input: {
            name,
            costCenters,
            clcDepartmentId,
          },
        },
      });
      showNotification(
        "success",
        "Departamento Creado",
        "Se ha creado el departamento correctamente."
      );
      setVisible(false);
      setCreating(false);
      setLoadingCreate(false);
      refetch();
    } catch {
      showNotification(
        "error",
        "Error al crear box",
        "El registro no se ha podido crear."
      );
      setLoadingCreate(false);
    }
  };

  const deleteRecord = async (id) => {
    setDeleteLoading(true);
    try {
      await deleteDepartment({ variables: { id } });
      showNotification(
        "success",
        "Registro eliminado",
        "Se ha eliminado el registro correctamente."
      );
      setDeleteLoading(false);
      refetch();
    } catch {
      showNotification(
        "error",
        "Error al eliminar",
        "El registro no se puede eliminar, ya que, cuenta con reservas a su nombre."
      );
      setDeleteLoading(false);
    }
  };

  const handleSearch = (input) => {
    const filteredDepartments = departments.filter((item) => {
      const searchableParams = ["costCenters[0].name", "name", "_id"];
      for (const param of searchableParams) {
        const field = _.get(item, `[${param}]`, "").toLowerCase();
        if (field.includes(input.toLowerCase())) return true;
      }
      return false;
    });
    setDeptartmentsFiltered(filteredDepartments);
  };

  useEffect(() => {
    if (loading) return;
    if (!data) return;
    if (data) {
      setDepartments(data.AllDepartments);
      setDeptartmentsFiltered(data.AllDepartments);
    }
  }, [loading, data]);

  return (
    <div className={gStyle.mainContent}>
      <div className={gStyle.createContainer}>
        <Button
          loading={creating}
          shape="round"
          className={gStyle.createButton}
          type="primary"
          onClick={() => {
            setCreating(true);
            setVisible(true);
          }}
        >
          Crear Registro
        </Button>
        <Input.Search
          placeholder="Buscar en Servicios"
          allowClear
          onChange={(e) => handleSearch(e.target.value)}
          onSearch={(e) => handleSearch(e)}
          style={{ width: "85%", margin: "0px" }}
        />
      </div>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        {deleteLoading && <LoadingMask />}
        <Render
          departments={departmentsFiltered}
          deleteRecord={deleteRecord}
          loading={loading}
        />
      </div>
      <CreateRecord
        visible={visible}
        loadingCreate={loadingCreate}
        onCreate={onCreate}
        onCancel={() => {
          setCreating(false);
          setVisible(false);
        }}
        ccData={ccData ? ccData.AllCostCenters : []}
      />
    </div>
  );
};

export default DepartmentScreen;
