import React from "react";
import styles from "./LoadingMask.module.css";
import { Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";

const LoadingMask = () => {
  const antIcon = <SyncOutlined style={{ fontSize: 20 }} spin />;
  return (
    <div className={styles.loading}>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default LoadingMask;
