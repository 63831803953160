import React from 'react';

import { Button, Form, Input } from 'antd';
import { BookFilled, EditOutlined } from '@ant-design/icons';

const { Item } = Form;

const CostCenterForm = ({ costCenter, handleSubmit }) => {
  const [form] = Form.useForm();
  const { clcCostCenterId, name } = costCenter;

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 5 }}
        onFinish={() =>
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              handleSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            })
        }
      >
        <Item name="name" label="Centro de costo" initialValue={name}>
          <Input prefix={<BookFilled />} bordered={false} readOnly />
        </Item>
        <Item
          name="clcCostCenterId"
          label="clcCostCenterId"
          initialValue={clcCostCenterId}
        >
          <Input
            bordered={false}
            suffix={<EditOutlined />}
            placeholder="Ingrese id de la clínica"
          />
        </Item>
        <Button htmlType="submit" block shape="round">
          Cambiar
        </Button>
      </Form>
    </>
  );
};

export default CostCenterForm;
