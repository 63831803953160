import React from 'react';
import { Route } from 'react-router-dom';
import PeriodScreen from './PeriodScreen';

const indexPeriod = () => {
  return (
    <>
      <Route exact path="/period" component={PeriodScreen} />
    </>
  );
};

export default indexPeriod;
