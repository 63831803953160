import React from "react";
import LoadingMask from "../../../Components/LoadingMask";
import { Modal, Form, Input } from "antd";

const CreateRecord = (props) => {
  const { visible, onCreate, onCancel, loadingCreate } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Ingrese datos"
      okText="Confirmar"
      cancelText="Cancelar"
      style={{ position: "relative" }}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {loadingCreate && <LoadingMask />}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="name"
          label="Feature"
          rules={[
            {
              required: true,
              message: "Por favor ingrese un feature",
            },
          ]}
        >
          <Input placeholder="Ingrese feature/característica" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateRecord;
