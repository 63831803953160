import React from "react";
import Handler from "./handler";
import Loading from "../../../Components/Loading";
import styles from "../ProfessionalScreen.module.css";

import { List, Empty } from "antd";

const { Item } = List;

function Render({ professionals, deleteRecord, loading }) {
  if (loading) return <Loading />;

  return professionals.length > 0 ? (
    <div className={styles.listContainer}>
      <List
        pagination={{ pageSize: 8 }}
        grid={{ gutter: 16, column: 4 }}
        dataSource={professionals}
        renderItem={(item) => (
          <Item>
            <Handler key={item._id} item={item} deleteRecord={deleteRecord} />
          </Item>
        )}
      />
    </div>
  ) : (
    <Empty />
  );
}

export default Render;
